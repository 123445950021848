import React from 'react';

import './style.scss';

import Navigation from '../Navigation';
import Waves from '../Waves';

const DarkNav = () => (
  <div className="dark-nav wave-bottom">
    {/* <div className="dark-shadow"></div>
    <div className="dark-bg"></div>
    <div className="texture"></div> */}
    <div className="nav-container">
      <Navigation />
    </div>
    <div className="wave-index shadowed-alpha dark-wave p-0">
      <Waves>
        {/* <div className="update-well">
            <div className="row m-0 equal">
              <div className="col-md">
                <div className="row no-gutters">
                  <div className="col-sm-9">
                    <h3>Update</h3>
                    <h2>2018 Season Parking Information</h2>
                    <p>This is an excerpt about the update.</p>
                  </div>
                  <div className="col-sm-3 text-right d-none d-sm-block">
                    <button className="btn btn-outline-light btn-sm">More Info</button>
                  </div>
                </div>
              </div>
              <div className="col-md">
                <div className="row no-gutters">
                  <div className="col-sm-9">
                    <h3>Service Update</h3>
                    <h2>Thanksgiving Service Cancellations</h2>
                    <p>This is a different excerpt about the update.</p>
                  </div>
                  <div className="col-sm-3 text-right d-none d-sm-block">
                    <button className="btn btn-outline-light btn-sm">More Info</button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
      </Waves>
    </div>
  </div>
);

export default DarkNav;
